import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return <button
    onClick={() =>
      loginWithRedirect({
        authorizationParams: {
          ui_locales: "ja",
        },
      })
    }>ログイン
  </button>;
};

export default LoginButton;
